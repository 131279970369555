import React from 'react';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import Image from '@nubank/www-latam-commons/components/Image/Image';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

function SectionNoTaxesVariant() {
  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1713550638-4-dobra-cartao-desk-1.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1713550638-4-dobra-cartao-desk-1.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1713550638-4-dobra-cartao-desk-1.jpg' },
  }]);

  return (
    <Box
      tag="section"
      backgroundColor="white"
      paddingVertical={{ xs: '12x', md: '20x', lg: '32x' }}
    >
      <Grid
        paddingLeft={{ xs: '8x', md: '16x' }}
        paddingRight={{ xs: '8x', md: '16x' }}
        rowGap={{ xs: '6x', md: '12x' }}
        alignItems={{ lg: 'center' }}
      >
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '8 / span 4' }}
        >
          <Image
            loading="lazy"
            srcSet={imageSrcSet}
            alt="Foto cartões nubank"
            Title="Cartão nubank"
          />
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '2 / span 5' }}
          gridRow={{ lg: '1' }}
        >
          <Typography
            variant="heading2"
            color="black"
            colorVariant="light"
            marginBottom="4x"
          >
            E tudo isso é de graça sim.
          </Typography>
          <Typography
            marginBottom="5x"
            variant="subtitle1"
          >
            Burocracia custa dinheiro. Então somos eficientes para você não ter que pagar nada.
          </Typography>
          <Link
            color="primary"
            href="https://nubank.com.br/perguntas/#como-o-nubank-ganha-dinheiro-se-nao-cobra-tarifas"
            target="_blank"
            variant="action"
          >
            Como isso é possível?
          </Link>
        </Grid.Child>
      </Grid>
    </Box>
  );
}

export default React.memo(SectionNoTaxesVariant);
